.fade-in-section {
  opacity: 0;
  transform: translateY(25vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.about {
  display: flex;
  flex-direction: row;
}

.meDiv {
  width: 250px !important;
  height: 250px !important;
  margin: 25px;
}

.me {
  align-self: flex-start;

  border-radius: 50% !important;
  width: 250px !important;
  height: 250px !important;
  padding: 2px;
  border: 2px solid wheat;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  margin-right: 15px;
}

.text {
  margin-bottom: 10px;
}

.textSet {
  margin-top: 10px;
}

.iconsDisplay {
  display: flex;
  justify-content: center;
  width: 100%;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.icons {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .meDiv {
    width: 150px !important;
    height: 150px !important;
    margin: 25px;
  }
  .me {
    border-radius: 50% !important;
    width: 150px !important;
    height: 150px !important;
  }
  .icons {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .textWrapper {
    font-size: 12px;
  }
}
