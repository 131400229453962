.containerCard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px;
}

.containerCard .boxCard {
  position: relative;
  max-width: 300px;
  height: 215px;
  background-color: #fff;
  padding: 7px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 202px rgba(0, 0, 0, 0.95);
  transition: 0.3s ease-in-out;
}

.containerCard .boxCard:hover {
  height: 350px;
}

.containerCard .boxCard {
  position: relative;
  width: 650px;
  height: 220px;
  object-fit: cover;
  top: -60px;
  left: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.containerCard .boxCard .imgBox .imgCard {
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
}

.containerCard .boxCard .contentBox {
  position: relative;
  margin-top: -140px;
  padding: 10px 15px;
  text-align: start;
  color: #111;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.containerCard .boxCard:hover .contentBox {
  visibility: visible;
  opacity: 1;
  margin-top: -5px;
  transition-delay: 0.15s;
}

.imgCard {
  border-radius: 2px;
  height: 160px !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
.boxCard {
  border-radius: 5px;
}

.hTitle {
  text-align: center;
}

.aLink {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: flex-end;
  margin-top: 10px;
  width: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100%;
  margin-top: 5%;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}
