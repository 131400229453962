.msgMe {
  display: flex;
  justify-content: center;
  color: whitesmoke;
}

.ContactForm {
  display: flex;
  justify-content: center;
}

.containerForm {
  width: 400px;
  height: 500px;
}

.row {
  width: 100%;
}

.contactForm {
  color: aliceblue;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.msgArea {
  padding: 10px;
  margin: 5px;
  width: 95%;
  padding: 20px;
  border-radius: 5px;
}

.submit-btn:before {
  content: attr(data-text);
  position: absolute;
  /* top: 25%; */
  left: 20%;
  transition: translate(-50%, -50%);
  color: #fff;
  font-size: 6em;
  font-weight: 700;
  pointer-events: none;
  opacity: 0;
  letter-spacing: 20px;
  transition: 0.9s;
  white-space: nowrap;
}

.submit-btn:hover:before {
  opacity: 0.1;
  letter-spacing: 0px;
}

.submit-btn {
  position: relative;
  display: inline-block;
  padding: 6px 15px;
  font-size: large;
  margin: 10px;
  text-decoration: none;
  background-color: #1871b8;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 2px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 1;
  border: none;
}

.submit-btn:hover li a {
  opacity: 0.1;
}

.submit-btn:hover {
  transform: scale(1.1);
  z-index: 1000;
  background-color: #75ccff;
  opacity: 1;
}

.submit-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  width: 80px;
  height: 100px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(225, 255, 255, 0.8),
    transparent
  );
  transform: skewX(35deg);
  transition: 0s;
}

.submit-btn:hover:before {
  left: calc(100% + 100px);
  transition: 0.5s;
  transition-delay: 0.2s;
}
