.App {
  display: flex;
  flex-direction: column;
  background: #0a2a43;
  min-height: 1500px;
}

html {
  scroll-behavior: smooth;
}
