@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  background: #0a2a43;
  min-height: 1500px;
}

section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

section:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #0a2a43, transparent);
  z-index: 10000;
}

section:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a2a43;
  z-index: 10000;
  mix-blend-mode: color;
}

section img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.road {
  z-index: 2;
}

.meName {
  position: relative;
  color: #fff;
  font-size: 7em;
  z-index: 1;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}

@media only screen and (max-width: 500px) {
  .meName {
    position: relative;
    color: #fff;
    font-size: 4em;
    z-index: 1;
    margin-left: 0.5em;
  }
}
