.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

ul {
  position: relative;
  display: flex;
  flex-direction: row;
  color: whitesmoke;
  padding-bottom: 5em;
}

ul li {
  list-style: none;
}

ul li:before {
  content: attr(data-text);
  position: absolute;
  /* top: 25%; */
  left: 20%;
  transition: translate(-50%, -50%);
  color: #fff;
  font-size: 6em;
  font-weight: 700;
  pointer-events: none;
  opacity: 0;
  letter-spacing: 20px;
  transition: 0.9s;
  white-space: nowrap;
}

ul li:hover:before {
  opacity: 0.1;
  letter-spacing: 0px;
}

ul li a {
  position: relative;
  display: inline-block;
  padding: 6px 15px;
  font-size: large;
  margin: 10px;
  text-decoration: none;
  background-color: #1871b8;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 2px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 1;
}

ul:hover li a {
  opacity: 0.1;
}

ul li a:hover {
  transform: scale(1.4);
  z-index: 1000;
  background-color: #75ccff;
  opacity: 1;
}

ul li a:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100px;
  width: 80px;
  height: 100px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(225, 255, 255, 0.8),
    transparent
  );
  transform: skewX(35deg);
  transition: 0s;
}

ul li a:hover:before {
  left: calc(100% + 100px);
  transition: 0.5s;
  transition-delay: 0.2s;
}

@media only screen and (max-width: 600px) {
  ul li a {
    position: relative;
    display: inline-block;
    padding: 6px 15px;
    font-size: x-small;
    margin: 10px;
    text-decoration: none;
    background-color: #1871b8;
    color: whitesmoke;
    text-transform: uppercase;
    letter-spacing: 2px;
    overflow: hidden;
    transition: 0.5s;
    z-index: 1;
  }
}
