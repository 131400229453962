@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.App{display:flex;flex-direction:column;background:#0a2a43;min-height:1500px}html{scroll-behavior:smooth}

*{margin:0;padding:0;font-family:"Poppins", sans-serif}body{background:#0a2a43;min-height:1500px}section{position:relative;width:100%;height:100vh;overflow:hidden;display:flex;justify-content:center;align-items:center}section:before{content:"";position:absolute;bottom:0;width:100%;height:100px;background:linear-gradient(to top, #0a2a43, transparent);z-index:10000}section:after{content:"";position:absolute;top:0;left:0;width:100%;height:100%;background:#0a2a43;z-index:10000;mix-blend-mode:color}section img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;pointer-events:none}.road{z-index:2}.meName{position:relative;color:#fff;font-size:7em;z-index:1}.spinner{display:flex;justify-content:center;align-items:center;margin-top:50%}@media only screen and (max-width: 500px){.meName{position:relative;color:#fff;font-size:4em;z-index:1;margin-left:0.5em}}

.menu{display:flex;justify-content:center;align-items:center;overflow:hidden}ul{position:relative;display:flex;flex-direction:row;color:whitesmoke;padding-bottom:5em}ul li{list-style:none}ul li:before{content:attr(data-text);position:absolute;left:20%;transition:translate(-50%, -50%);color:#fff;font-size:6em;font-weight:700;pointer-events:none;opacity:0;letter-spacing:20px;transition:0.9s;white-space:nowrap}ul li:hover:before{opacity:0.1;letter-spacing:0px}ul li a{position:relative;display:inline-block;padding:6px 15px;font-size:large;margin:10px;text-decoration:none;background-color:#1871b8;color:whitesmoke;text-transform:uppercase;letter-spacing:2px;overflow:hidden;transition:0.5s;z-index:1}ul:hover li a{opacity:0.1}ul li a:hover{transform:scale(1.4);z-index:1000;background-color:#75ccff;opacity:1}ul li a:before{content:"";position:absolute;top:0;left:-100px;width:80px;height:100px;background:linear-gradient(90deg, transparent, rgba(225,255,255,0.8), transparent);transform:skewX(35deg);transition:0s}ul li a:hover:before{left:calc(100% + 100px);transition:0.5s;transition-delay:0.2s}@media only screen and (max-width: 600px){ul li a{position:relative;display:inline-block;padding:6px 15px;font-size:x-small;margin:10px;text-decoration:none;background-color:#1871b8;color:whitesmoke;text-transform:uppercase;letter-spacing:2px;overflow:hidden;transition:0.5s;z-index:1}}

.fade-in-section{opacity:0;transform:translateY(25vh);visibility:hidden;transition:opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;will-change:opacity, transform, visibility}.fade-in-section.is-visible{opacity:1;transform:none;visibility:visible}.about{display:flex;flex-direction:row}.meDiv{width:250px !important;height:250px !important;margin:25px}.me{align-self:flex-start;border-radius:50% !important;width:250px !important;height:250px !important;padding:2px;border:2px solid wheat}.textWrapper{display:flex;flex-direction:column;color:whitesmoke;margin-right:15px}.text{margin-bottom:10px}.textSet{margin-top:10px}.iconsDisplay{display:flex;justify-content:center;width:100%;align-content:center;margin-top:2%;margin-bottom:2%}.icons{border-radius:50%;display:flex;justify-content:center;width:100px;height:100px;margin-left:20px}@media only screen and (max-width: 600px){.meDiv{width:150px !important;height:150px !important;margin:25px}.me{border-radius:50% !important;width:150px !important;height:150px !important}.icons{border-radius:50%;width:50px;height:50px}.textWrapper{font-size:12px}}

.cardsDisplay{display:flex;justify-content:center;box-sizing:border-box;margin-top:10px;padding:0}.cardsGrid{display:grid;grid-template-columns:repeat(2, 1fr);grid-auto-rows:minmax(50px, auto)}@media (min-width: 1025px){.cardsGrid{grid-template-columns:repeat(3, 1fr)}}@media only screen and (max-width: 1440px){.cardsGrid{grid-template-columns:repeat(3, 1fr);justify-content:center}}@media only screen and (max-width: 768px){.cardsGrid{grid-template-columns:repeat(1, 1fr);justify-content:center}}

.containerCard{display:flex;width:100%;flex-wrap:wrap;padding:20px}.containerCard .boxCard{position:relative;max-width:300px;height:215px;background-color:#fff;padding:7px 15px;display:flex;flex-direction:column;box-shadow:0 5px 202px rgba(0,0,0,0.95);transition:0.3s ease-in-out}.containerCard .boxCard:hover{height:350px}.containerCard .boxCard{position:relative;width:650px;height:220px;object-fit:cover;top:-60px;left:20px;box-shadow:0 5px 20px rgba(0,0,0,0.2);z-index:1}.containerCard .boxCard .imgBox .imgCard{max-width:100%;height:100%;border-radius:5px}.containerCard .boxCard .contentBox{position:relative;margin-top:-140px;padding:10px 15px;text-align:start;color:#111;visibility:hidden;opacity:0;transition:0.3s ease-in-out}.containerCard .boxCard:hover .contentBox{visibility:visible;opacity:1;margin-top:-5px;transition-delay:0.15s}.imgCard{border-radius:2px;height:160px !important;box-shadow:0 5px 20px rgba(0,0,0,0.5)}.boxCard{border-radius:5px}.hTitle{text-align:center}.aLink{text-decoration:none;display:flex;justify-content:center;align-content:flex-end;margin-top:10px;width:100%}.spinner{display:flex;justify-content:center;align-content:center;height:100vh;width:100%;margin-top:5%}.visible{visibility:visible}.invisible{visibility:hidden}

.msgMe{display:flex;justify-content:center;color:whitesmoke}.ContactForm{display:flex;justify-content:center}.containerForm{width:400px;height:500px}.row{width:100%}.contactForm{color:aliceblue}.form-control{width:100%;padding:10px;margin:5px;border-radius:5px}.msgArea{padding:10px;margin:5px;width:95%;padding:20px;border-radius:5px}.submit-btn:before{content:attr(data-text);position:absolute;left:20%;transition:translate(-50%, -50%);color:#fff;font-size:6em;font-weight:700;pointer-events:none;opacity:0;letter-spacing:20px;transition:0.9s;white-space:nowrap}.submit-btn:hover:before{opacity:0.1;letter-spacing:0px}.submit-btn{position:relative;display:inline-block;padding:6px 15px;font-size:large;margin:10px;text-decoration:none;background-color:#1871b8;color:whitesmoke;text-transform:uppercase;letter-spacing:2px;overflow:hidden;transition:0.5s;z-index:1;border:none}.submit-btn:hover li a{opacity:0.1}.submit-btn:hover{transform:scale(1.1);z-index:1000;background-color:#75ccff;opacity:1}.submit-btn:before{content:"";position:absolute;top:0;left:-100px;width:80px;height:100px;background:linear-gradient(90deg, transparent, rgba(225,255,255,0.8), transparent);transform:skewX(35deg);transition:0s}.submit-btn:hover:before{left:calc(100% + 100px);transition:0.5s;transition-delay:0.2s}

.footerDiv{width:100%;height:100px;margin-bottom:0%;margin-top:auto;background-color:#283850;border-top:black 1px solid}.copy{display:flex;justify-content:center;color:whitesmoke;font-size:smaller}.footericon{display:flex;justify-content:center;align-content:center;position:relative}.aIcon{position:relative}.imgIcon{margin:10px;width:50px;height:50px}

