.footerDiv {
  width: 100%;
  height: 100px;
  margin-bottom: 0%;
  margin-top: auto;
  background-color: rgb(40, 56, 80);
  border-top: black 1px solid;
}

.copy {
  display: flex;
  justify-content: center;
  color: whitesmoke;
  font-size: smaller;
}

.footericon {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}

.aIcon {
  position: relative;
}

.imgIcon {
  margin: 10px;
  width: 50px;
  height: 50px;
}
