.cardsDisplay {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 0;
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(50px, auto);
}

@media (min-width: 1025px) {
  .cardsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1440px) {
  .cardsGrid {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .cardsGrid {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
}
